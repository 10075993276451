import React, { useEffect, useState } from 'react';
import { Loader, StatusPill } from '../../../designSystem/DesignSystem';
import { showErrorMes } from '../../../helpers/NotificationHelper';
import { useApi } from '../../../hooks/UseApi';
import { useTranslation } from 'react-i18next';
import TestRunExecutionAutomationStepApi from '../../../api/TestRunExecutionAutomationStepApi';
import TestCaseAutomationStepApi from '../../../api/TestCaseAutomationStepApi';

const TestRunExecutionAutomationStepsTab = (props) => {
  const { execution } = props;
  const { t } = useTranslation();

  const [executionAutomationSteps, setExecutionAutomationSteps] = useState([]);
  const [sortExecutionStepsFunction, setSortExecutionStepsFunction] = useState(null);

  const getTestCaseAutomationSteps = useApi({
    name: 'fetching test case automation steps',
    api: TestCaseAutomationStepApi.getSteps,
    autoLoad: false,
    onSuccess: () => {
      if (getTestCaseAutomationSteps?.data) {
        const descriptionOrder = getTestCaseAutomationSteps.data.map((step) => step.description);
        const sortExecutionSteps = (executionSteps) => {
          return executionSteps.sort((a, b) => {
            return descriptionOrder.indexOf(a.description) - descriptionOrder.indexOf(b.description);
          });
        };
        setSortExecutionStepsFunction(() => sortExecutionSteps);
      }
    },
    onError: (err) => showErrorMes(err)
  });

  const getExecutionAutomationSteps = useApi({
    name: 'get execution automation steps',
    api: TestRunExecutionAutomationStepApi.getSteps,
    autoLoad: false,
    onSuccess: () => {
      if (getExecutionAutomationSteps?.data) {
        const sortedData = sortExecutionStepsFunction
          ? sortExecutionStepsFunction(getExecutionAutomationSteps.data)
          : getExecutionAutomationSteps.data;

        setExecutionAutomationSteps(sortedData);
      }
    },
    onError: (err) => showErrorMes(err)
  });

  const getSteps = () => {
    getTestCaseAutomationSteps.execute({ testCaseId: execution?.testCase?.id });
    getExecutionAutomationSteps.execute({ testRunExecutionId: execution?.id });
  };

  useEffect(() => {
    if (execution?.id) {
      getSteps();
    }
  }, [execution]);

  return (
    <div className="grid h-176 flex-grow grid-cols-6 divide-y overflow-y-auto">
      <div className="col-span-6">
        <div className="flex w-full flex-col gap-4 overflow-x-auto">
          <div className="divide-y">
            <table className="w-full">
              <thead className="overflow-x-auto overflow-y-auto bg-gray-50">
                <tr className=" text-left text-xs font-medium tracking-wide  text-gray-500">
                  <th className=" px-2 py-3"> #</th>
                  {['Description'].map((header, index) => (
                    <th
                      key={index}
                      className="w-1/2 px-2 py-3"
                    >
                      {' '}
                      {t(header)}{' '}
                    </th>
                  ))}
                  {['Result'].map((header, index) => (
                    <th
                      key={index}
                      className="w-1/2 px-2 py-3"
                    >
                      {' '}
                      {t(header)}{' '}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="w-full divide-y divide-gray-200  overflow-x-hidden bg-white">
                {getExecutionAutomationSteps?.loading ? (
                  <Loader />
                ) : (
                  (executionAutomationSteps || []).map((step, index) => (
                    <tr key={index}>
                      <td className="whitespace-nowrap px-1 py-4">
                        <div className="overflow-hidden overflow-ellipsis  whitespace-nowrap text-sm text-gray-500">
                          {index + 1}
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4">
                        <div className="overflow-hidden overflow-ellipsis  whitespace-nowrap text-sm text-gray-500">
                          {step?.description}
                        </div>
                      </td>
                      <td className="col-span-2 self-center overflow-auto px-2 py-4 ">
                        <StatusPill value={step.result} />
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestRunExecutionAutomationStepsTab;
