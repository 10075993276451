import React, { useEffect, useState } from 'react';
import TestCaseAutomationStepApi from '../../api/TestCaseAutomationStepApi';
// Design System Import
import { Body, Heading } from '../../designSystem/DesignSystem';
//Helper Imports
import { isValidValue } from '../../helpers/Helper';
import { showErrorMes } from '../../helpers/NotificationHelper';
import { useTranslation } from 'react-i18next';
// API Imports
import { useApi } from '../../hooks/UseApi';

const TestRunAutomationSteps = (props) => {
  const { testCaseId, caseApproved, preconditions } = props;
  const [testSteps, setTestSteps] = useState([]);
  const { t } = useTranslation();

  const stepLoader = useApi({
    name: 'fetching test case automation steps',
    api: TestCaseAutomationStepApi.getSteps,
    autoLoad: false,
    onSuccess: () => setTestSteps(stepLoader?.data),
    onError: (err) => showErrorMes(err)
  });

  useEffect(() => {
    if (testCaseId) {
      stepLoader.execute({ testCaseId });
    }
  }, [testCaseId]);

  return (
    <>
      <div className="divide-y">
        {isValidValue(preconditions) && (
          <div className=" m-4 flex flex-row">
            <div className={`flex-row bg-white`}>
              <Heading
                className="w-36"
                type="small"
                text={t('Preconditions')}
              />
              <div className={'max-h-32 overflow-y-auto'}>
                <Body
                  type="medium"
                  text={preconditions}
                />
              </div>
            </div>
          </div>
        )}

        <table
          className="flex flex-col py-4"
          border="1"
        >
          <thead className="flex flex-grow">
            <tr className="flex flex-grow">
              <th className="flex w-24">
                <Heading
                  type="xsmall"
                  text="#"
                  uppercase
                />
              </th>
              <th className="flex flex-grow">
                <Heading
                  type="xsmall"
                  text={t('Description')}
                  uppercase
                />
              </th>
              {caseApproved === false && <th className="w-24"></th>}
            </tr>
          </thead>
        </table>
        <div>
          {stepLoader.loading ? (
            <p>Loading</p>
          ) : (
            <>
              {testSteps.length === 0 && caseApproved && !stepLoader.loading && (
                <Body
                  type="medium"
                  text={t('There are no steps.')}
                />
              )}
              {!caseApproved && !stepLoader.loading && (
                <Body
                  type="medium"
                  text={t('User cannot make changes to automation steps.')}
                />
              )}
              <>
                {testSteps.map((item, index) => (
                  <div
                    key={item.id}
                    className="grid grid-cols-12 gap-4"
                  >
                    <div className="col-span-1 self-center">
                      <Body
                        type="small"
                        text={index + 1}
                      />
                    </div>
                    <div className="col-span-5 self-center">
                      <Body
                        type="medium"
                        text={item?.description}
                      />
                    </div>
                  </div>
                ))}
              </>
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default TestRunAutomationSteps;
