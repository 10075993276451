import React, { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
//Api Import
import { useApi } from '../../../hooks/UseApi';
import ProjectConfigApi from '../../../api/ProjectConfigApi';
//Hook - Context Imports
import { useAppDispatch } from '../../../context/AppContext';
import { saveProjectConfig } from '../../../context/ProjectActions';
import UseQueryProject from '../../../hooks/UseQueryProject';
import UseProjectConfig from '../../../hooks/UseProjectConfig';
//Component Imports
import ToggleCard from '../../../components/card/ToggleCard';
import { SidePaneRightContent } from '../../../components/Layout';
import { Heading, Loader } from '../../../designSystem/DesignSystem';
//Helper Import
import { showErrorMes, showSuccessMes, INFO_MESSAGES } from '../../../helpers/NotificationHelper';
import { isProjectManager } from '../../../helpers/PriviligeHelper';
import { getRunName, getTestCaseName } from '../../../helpers/ArtifactNameHelper';

const AutomationProjectSettings = () => {
    const { t } = useTranslation();
    const { projectId } = UseQueryProject('projectId');
    const dispatch = useAppDispatch();
    const projectConfig = UseProjectConfig(projectId);
    const testCaseName = useMemo(() => getTestCaseName(projectConfig), [projectConfig]);
    const runName = useMemo(() => getRunName(projectConfig), [projectConfig]);

    const [showAutomationSteps, setShowAutomationSteps] = useState(
        projectConfig?.configuration?.automation?.showAutomationSteps + '' === 'true'
    );

    const [showTestRunAutomationSteps, setShowTestRunAutomationSteps] = useState(
        projectConfig?.configuration?.automation?.showTestRunAutomationSteps + '' === 'true'
    );

    const updateProjectConfig = useApi({
        name: 'Updating project config',
        api: ProjectConfigApi.updateProjectConfiguration,
        onSuccess: () => {
            showSuccessMes(INFO_MESSAGES.save);
            dispatch(saveProjectConfig(projectId, updateProjectConfig?.data));
        },
        onError: (err) => showErrorMes(err)
    });

    const projectConfigLoader = useApi({
        name: 'Get project configs',
        api: ProjectConfigApi.get,
        onSuccess: () => {
            dispatch(saveProjectConfig(projectId, projectConfigLoader?.data));
        },
        onError: (err) => showErrorMes(err)
    });

    /*
    const handleSubmit = (value) => {
        const body = { id: projectId, key: 'showAutomationSteps', value };
        updateProjectConfig.execute(body);
    };
    */


    const handleSubmit = (value, name) => {
        if (projectConfig?.configuration?.automation?.[name] == value) {
            return;
        }

        var body = { id: projectId, key: name, value };
        updateProjectConfig.execute(body);
    };

    useEffect(() => {
        if (projectId) {
            projectConfigLoader.execute({ id: projectId });
        }
    }, [projectId]);

    return (
        <>
            <SidePaneRightContent.Top>
                <Heading
                    type="large"
                    text={t('Automation')}
                />
            </SidePaneRightContent.Top>
            <SidePaneRightContent.Main>
                <SidePaneRightContent.Center colFull={true}>
                    {projectConfigLoader?.loading ? (
                        <Loader></Loader>
                    ) : (
                        isProjectManager(projectId) && (
                            <div>
                                <div className="mt-4">
                                    <ToggleCard
                                        name="showAutomationStepsUpdate"
                                        disabled={false}
                                        title={t('showAutomationStepsUpdateTitle_entity', { entity: t(testCaseName) })}
                                        labelText={t('showAutomationStepsUpdate_entity', { entity: t(testCaseName) })}
                                        checked={showAutomationSteps}
                                        submitCallBack={(value) => {
                                            handleSubmit(value, 'showAutomationSteps');
                                            setShowAutomationSteps(value);
                                        }}
                                    />
                                </div>
                                <div className="mt-4">
                                    <ToggleCard
                                        name="sshowTestRunAutomationStepsUpdate"
                                        disabled={false}
                                        title={t('showAutomationStepsUpdateTitle_entity', { entity: t(runName) })}
                                        labelText={t('showAutomationStepsUpdate_entity', { entity: t(runName) })}
                                        checked={showTestRunAutomationSteps}
                                        submitCallBack={(value) => {
                                            handleSubmit(value, 'showTestRunAutomationSteps');
                                            setShowTestRunAutomationSteps(value);
                                        }}
                                    />
                                </div>
                            </div>
                        )
                    )}
                </SidePaneRightContent.Center>
            </SidePaneRightContent.Main>
        </>
    );
};

export default AutomationProjectSettings;
