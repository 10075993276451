import React, { useEffect, useState, useMemo, Fragment } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Tab } from '@headlessui/react';
// Api and Hook Imports
import { useApi } from '../../hooks/UseApi';
import UseProjectConfig from '../../hooks/UseProjectConfig';
import UseQueryProject from '../../hooks/UseQueryProject';
import InsightApi from '../../api/InsightApi';
import UsePrevious from '../../hooks/UsePrevious';
import { useTranslation } from 'react-i18next';
// Design Imports
import { Button } from '../../designSystem/DesignSystem';
// Component Imports
import SelectedTableFilters from '../filtering/SelectedTableFilters';
import Comment from '../../pages/comment/Comment';
import DefectReactTable from '../../pages/defect/DefectReactTable';
import DefectModal from '../../pages/defect/Modal/DefectModal';
import HistoryDetails from '../../pages/history/HistoryDetails';
import StatusHistory from '../../pages/history/StatusHistory';
import AddRequirementModal from '../../pages/release/modal/AddRequirementModal';
import RequirementAddTestCasesModal from '../../pages/requirement/modal/RequirementAddTestCasesModal';
import RequirementReactTable from '../../pages/requirement/RequirementReactTable';
import TestRunExecutions from '../../pages/tasks/TestRunExecutions';
import TestRunReactTable from '../../pages/tasks/TestRunReactTable';
import TestRunSteps from '../../pages/tasks/TestRunSteps';
import LastRunExecutionDetails from '../../pages/testCase/LastRunExecutionDetails';
import TestCaseRunHistoryReactTable from '../../pages/testCase/table/TestCaseRunHistoryReactTable';
import TestCaseReactTable from '../../pages/testCase/table/TestCaseReactTable';
import TestCaseSteps from '../../pages/testCase/TestCaseSteps';
import TestCaseAutomationSteps from '../../pages/testCase/TestCaseAutomationSteps';
import TestCycleReactTable from '../../pages/testPlanning/testCycle/TestCycleReactTable';
import TestSuiteReactTable from '../../pages/testPlanning/testSuite/TestSuiteReactTable';
import TestSuiteCreateRunModal from '../testrun/TestSuiteCreateRunModal';
import Upload from '../upload/Upload';
// Helper Imports
import {
  getArtifactName,
  getExecutionName,
  getRequirementName,
  getRunName,
  getTestCaseName
} from '../../helpers/ArtifactNameHelper';
import { hasPrivilege, isViewTestRun, isSystemAdmin } from '../../helpers/PriviligeHelper';
import { isApproved } from '../../helpers/TestCaseHelper';
import { CycleLockEnums } from '../../pages/testPlanning/testCycle/lockAction/LockActionHelper';
import { classNames, isCertainTrue, isValidValue } from '../../helpers/Helper';
import { ENTITY_TYPES, HISTORY_MENU, PRIVILEGES } from '../../constants';
// TODO: WIP Parameters feature
// import ConfigureParametersModal from '../testrun/ConfigureParametersModal';
import { translateStatusHistory } from '../../helpers/TranslationHelper';
import TestRunAutomationSteps from '../../pages/tasks/TestRunAutomationSteps';

export const BOTTOM_RIGHT_BUTTON_SIZE = 'xsmall';

const CommonEntityAdvancedTab = (props) => {
  const { t } = useTranslation();

  const { config, tableHeight } = props;
  const {
    entityId,
    entityType,
    users,
    refreshStatus,
    setRefreshStatus,
    dataForm,
    jiraConfig,
    selectedItem,
    submitCallBack,
    filter,
    selectedExe,
    setRefreshTree
  } = config;

  const { projectId } = UseQueryProject('projectId');
  const projectConfig = UseProjectConfig(projectId);
  const [testCaseModal, setTestCaseModal] = useState(false);

  const [refreshTable, setRefreshTable] = useState(false);
  const [isAddRequirementModalOpen, setIsAddRequirementModalOpen] = useState(false);

  const [defectModal, setDefectModal] = useState(false);
  const [allFilters, setAllFilters] = useState({});
  const [addTestcaseModalVis, setAddTestcaseModalVis] = useState(false);
  const [attachmentCount, setAttachmentCount] = useState(0);
  const [commentCount, setCommentCount] = useState(0);
  // TODO: WIP Parameters feature
  // const [configureParametersModalOpen, setConfigureParametersModalOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const runId = useMemo(() => (searchParams?.get('runId') ? searchParams?.get('runId') : null), [searchParams]);
  const defectId = useMemo(
    () => (searchParams?.get('defectId') ? searchParams?.get('defectId') : null),
    [searchParams]
  );
  const prevRunId = UsePrevious(runId);
  const prevDefectId = UsePrevious(defectId);

  // <-- API SETUP -->
  const getCounts = useApi({
    name: 'get attachment and comment counts',
    api: InsightApi.getAttachmentAndCommentCount,
    autoLoad: false,
    onSuccess: () => {
      setAttachmentCount(getCounts?.data?.attachmentCount);
      setCommentCount(getCounts?.data?.commentCount);
      fillAllOptions();
    }
  });

  const commonFilterBoxs = (typeInput, newFilters) => {
    return (
      <div className="flex flex-grow gap-2">
        <SelectedTableFilters
          allFilters={allFilters}
          defaultFilters={{ ...filter, ...newFilters, projectId }}
          entityId={entityId}
          setAllFilters={setAllFilters}
          entityType={typeInput}
          setRefreshTree={setRefreshTree}
          setRefreshTable={setRefreshTable}
          {...(entityType === ENTITY_TYPES.TEST_CONTAINER && { showBulkButton: true })}
          {...(entityType === ENTITY_TYPES.FOLDER && { showContribution: true })}
        />
      </div>
    );
  };

  const getDefectTab = (customEntityFilter) => {
    return {
      label: getArtifactName(projectConfig, ENTITY_TYPES.DEFECT),
      disabled: false,
      topRight: () => (
        <>
          {hasPrivilege(projectId, PRIVILEGES.CREATE_DEFECT) && (
            <div className="mt-2 flex justify-end gap-2">
              <Button
                onClick={() => setDefectModal(true)}
                type="button"
                icon="Plus"
                text={getArtifactName(projectConfig, ENTITY_TYPES.DEFECT)}
                secondary
                size={BOTTOM_RIGHT_BUTTON_SIZE}
              />
            </div>
          )}
        </>
      ),
      bottomLeft: () => (
        <>
          <div className="flex flex-grow justify-start gap-4">{commonFilterBoxs(ENTITY_TYPES.DEFECT)}</div>
        </>
      ),
      render: () => (
        <>
          <DefectReactTable
            refreshStatus={refreshTable}
            setRefreshStatus={setRefreshTable}
            filter={{ ...customEntityFilter, ...allFilters }}
            users={users}
          />
        </>
      )
    };
  };

  const getRunTab = (customEntityFilter) => {
    return {
      label: getArtifactName(projectConfig, ENTITY_TYPES.TEST_RUN),
      disabled: false,
      topRight: () => (
        <>
          <div className="mt-2 flex justify-end gap-2">
            {entityType === ENTITY_TYPES.TEST_SUITE &&
              hasPrivilege(projectId, PRIVILEGES.CREATE_UPDATE_TEST_RUN) &&
              selectedItem?.lockStatus?.[CycleLockEnums.update] !== true && (
                <div className="flex justify-end gap-4">
                  <Button
                    secondary
                    onClick={() => setAddTestcaseModalVis(true)}
                    icon="plus"
                    size={BOTTOM_RIGHT_BUTTON_SIZE}
                    text={`${getTestCaseName(projectConfig)} / ${getRequirementName(projectConfig)} `}
                  />
                </div>
              )}
          </div>
        </>
      ),
      bottomLeft: () => (
        <>
          <div className="flex flex-grow justify-start gap-4">{commonFilterBoxs(ENTITY_TYPES.TEST_RUN)}</div>
        </>
      ),
      render: () => (
        <>
          <TestRunReactTable
            refreshStatus={refreshTable}
            setRefreshStatus={setRefreshTable}
            filter={{ ...customEntityFilter, ...allFilters }}
            projectConfig={projectConfig}
            jiraConfig={jiraConfig}
            users={users}
          />
        </>
      )
    };
  };

  const assignReqButton = (
    <div>
      <Button
        id="requirement-assign-button"
        secondary
        text={t('assign_entity', { entity: getRequirementName(projectConfig) })}
        icon="Plus"
        size={BOTTOM_RIGHT_BUTTON_SIZE}
        disabled={dataForm?.lockStatus?.assignRequirement}
        onClick={() => setIsAddRequirementModalOpen(true)}
      />
    </div>
  );

  const attachment = useMemo(
    () => (
      <label className="flex cursor-pointer">
        {t('Attachment ')}
        {parseInt(attachmentCount, 10) !== 0 && (
          <div className="mx-2 block w-6 rounded-full bg-primary-500 text-xs text-white">{attachmentCount}</div>
        )}
      </label>
    ),
    [attachmentCount, t]
  );

  const comment = useMemo(() => {
    return (
      <label className="flex cursor-pointer">
        {t('Comment ')}
        {parseInt(commentCount, 10) !== 0 && (
          <div className="mx-2 block w-6 rounded-full bg-primary-500 text-xs  text-white">{commentCount}</div>
        )}
      </label>
    );
  }, [commentCount, t]);

  const defectOptions = [
    {
      label: translateStatusHistory(),
      disabled: false,
      render: () => (
        <StatusHistory
          users={users}
          entityId={entityId}
          entityType={entityType}
          refreshStatus={refreshStatus}
          setRefreshStatus={setRefreshStatus}
        />
      )
    }
  ];
  const runOptions = [
    {
      label: t('Steps'),
      disabled: false,
      render: () => (
        <TestRunSteps
          runId={entityId}
          projectConfig={projectConfig}
          preconditions={dataForm?.testCase?.preconditions}
        />
      )
    },
    ...(
      isCertainTrue(projectConfig?.configuration?.automation?.showTestRunAutomationSteps)
        ? [{
            label: t('Automation Steps'),
            disabled: false,
            render: () => (
              <TestRunAutomationSteps
                testCaseId={dataForm?.testCase?.id}
                caseApproved={isApproved(dataForm?.testCase?.approveType)}
              />
            )
          }]
        : []),
    { ...getDefectTab({ ...filter, testRunId: entityId }) },
    {
      label: t('Execution History'),
      disabled: false,
      render: () => (
        <div className="overflow-x-auto">
          <TestRunExecutions
            runId={entityId}
            projectId={projectId}
            selectedExe={selectedExe}
            users={users}
            submitCallBack={submitCallBack}
          />{' '}
        </div>
      )
    }
  ];

  const testFolderOptions = [
    { ...getRunTab(filter) },
    {
      label: getArtifactName(projectConfig, ENTITY_TYPES.TEST_CYCLE),
      disabled: false,

      bottomLeft: () => (
        <>
          <div className="flex flex-grow justify-start gap-4">{commonFilterBoxs(ENTITY_TYPES.TEST_CYCLE)}</div>
        </>
      ),
      render: () => (
        <>
          <TestCycleReactTable
            refreshStatus={refreshTable}
            setRefreshStatus={setRefreshTable}
            users={users}
            filter={{ ...filter, ...allFilters }}
            height={tableHeight}
            projectConfig={projectConfig}
          />
        </>
      )
    },
    {
      label: getArtifactName(projectConfig, ENTITY_TYPES.TEST_SUITE),
      disabled: false,
      bottomLeft: () => (
        <>
          <div className="flex flex-grow justify-start gap-4">{commonFilterBoxs(ENTITY_TYPES.TEST_SUITE)}</div>
        </>
      ),
      render: () => (
        <>
          <TestSuiteReactTable
            users={users}
            refreshStatus={refreshTable}
            setRefreshStatus={setRefreshTable}
            filter={{
              ...filter,
              testFolderId: entityId,
              withChilds: true,
              withTestFolderChilds: true,
              withSubCycleSuites: true,
              ...allFilters
            }}
            projectConfig={projectConfig}
          />
        </>
      )
    }
  ];

  const cycleOptions = [
    { ...getRunTab(filter) },
    {
      label: getArtifactName(projectConfig, ENTITY_TYPES.TEST_SUITE),
      disabled: false,
      bottomLeft: () => (
        <>
          <div className="flex flex-grow justify-start gap-4">{commonFilterBoxs(ENTITY_TYPES.TEST_SUITE)}</div>
        </>
      ),
      render: () => (
        <>
          <TestSuiteReactTable
            users={users}
            refreshStatus={refreshTable}
            setRefreshStatus={setRefreshTable}
            filter={{ ...filter, testCycleId: entityId, withChilds: true, withSubCycleSuites: true, ...allFilters }}
            height={tableHeight}
            projectConfig={projectConfig}
          />
        </>
      )
    },
    { ...getDefectTab({ ...filter, testCycleId: entityId }) },
    {
      label: translateStatusHistory(),
      disabled: false,
      render: () => (
        <StatusHistory
          users={users}
          entityId={entityId}
          entityType={entityType}
          refreshStatus={refreshStatus}
          setRefreshStatus={setRefreshStatus}
        />
      )
    }
  ];

  const suiteOptions = [
    { ...getRunTab(filter) },
    {
      label: getArtifactName(projectConfig, ENTITY_TYPES.TEST_SUITE),
      disabled: false,
      bottomLeft: () => (
        <>
          <div className="flex flex-grow justify-start gap-4">{commonFilterBoxs(ENTITY_TYPES.TEST_SUITE)}</div>
        </>
      ),
      render: () => (
        <>
          <TestSuiteReactTable
            users={users}
            refreshStatus={refreshTable}
            setRefreshStatus={setRefreshTable}
            filter={{ ...filter, parentId: entityId, withChilds: true, ...allFilters }}
            height={tableHeight}
            projectConfig={projectConfig}
          />
        </>
      )
    },
    { ...getDefectTab({ ...filter, testSuiteId: entityId }) }
  ];

  const caseOptions = [
    {
      label: t('Steps'),
      disabled: false,
      render: () => (
        <TestCaseSteps
          tableHeight={tableHeight}
          preconditions={dataForm?.preconditions}
          testCaseId={dataForm?.id}
          caseApproved={isApproved(dataForm?.approveType)}
          projectConfig={projectConfig}
          projectId={projectId}
        />
      )
    },
    ...(
      isCertainTrue(projectConfig?.configuration?.automation?.showAutomationSteps)
        ? [{
          label: t('Automation Steps'),
          disabled: false,
          render: () => (
            <TestCaseAutomationSteps
              tableHeight={tableHeight}
              preconditions={dataForm?.preconditions}
              testCaseId={dataForm?.id}
              caseApproved={isApproved(dataForm?.approveType)}
            />
          )
        }]
        : []),
    ...(hasPrivilege(projectId, PRIVILEGES.VIEW_TESTCASE_REQUIREMENT)
      ? [
          {
            label: getRequirementName(projectConfig),
            disabled: false,
            topRight: () => (
              <>
                <div className="mt-2 flex justify-end gap-2">
                  {hasPrivilege(projectId, PRIVILEGES.ASSIGN_TESTCASE_REQUIREMENT) &&
                    !isApproved(dataForm?.approveType) && (
                      <div className="flex justify-end gap-4">{assignReqButton}</div>
                    )}
                </div>
              </>
            ),
            bottomLeft: () => (
              <>
                <div className="flex flex-grow justify-start gap-4">
                  {commonFilterBoxs(ENTITY_TYPES.REQUIREMENT, { testCaseId: dataForm?.id, includeReleases: false })}
                </div>
              </>
            ),
            render: () => (
              <>
                {/* burada entityid icin dataform idsi daha uygun, versiyonlardan dolayi problem olmasin diye */}
                <RequirementReactTable
                  filter={{
                    testCaseId: dataForm?.id,
                    includeReleases: false,
                    projectId,
                    ...allFilters
                  }}
                  users={users}
                  showCheckbox={true}
                  entityId={dataForm?.id}
                  entityType={ENTITY_TYPES.TEST_CASE}
                  refreshStatus={refreshTable}
                  setRefreshStatus={setRefreshTable}
                  tableHeight={tableHeight}
                />
              </>
            )
          }
        ]
      : []),
    ...(isApproved(dataForm?.approveType) && isViewTestRun(projectId)
      ? [
          {
            label: t('entity_history', { entity: getRunName(projectConfig) }),
            disabled: false,
            bottomRight: () => (
              <>
                <div className="flex gap-2">
                  {hasPrivilege(projectId, PRIVILEGES.ASSIGN_TESTCASE_REQUIREMENT) &&
                    !isApproved(dataForm?.approveType) && (
                      <div className="flex justify-end gap-4">{assignReqButton}</div>
                    )}
                </div>
              </>
            ),
            render: () => (
              <>
                <TestCaseRunHistoryReactTable
                  filter={{ testCaseId: entityId }}
                  refreshStatus={refreshTable}
                  setRefreshStatus={setRefreshTable}
                  tableHeight={tableHeight}
                />
              </>
            )
          }
        ]
      : []),

    ...(isApproved(dataForm?.approveType) && isViewTestRun(projectId)
      ? [
          {
            label: t('execution_lastRun', { executionName: getExecutionName(projectConfig) }),
            disabled: false,
            render: () => (
              <LastRunExecutionDetails
                lastTestRunExecution={dataForm?.lastTestRunExecution}
                lastTestRun={dataForm?.lastTestRun}
                projectId={projectId}
                users={users}
                tableHeight={tableHeight}
                jiraConfig={jiraConfig}
              />
            )
          }
        ]
      : [])
  ];

  const containerOptions = [
    {
      label: getArtifactName(projectConfig, ENTITY_TYPES.TEST_CASE),
      disabled: false,
      bottomLeft: () => (
        <>
          <div className="flex flex-grow justify-start gap-4">{commonFilterBoxs(ENTITY_TYPES.TEST_CASE)}</div>
        </>
      ),
      render: () => (
        <>
          <TestCaseReactTable
            filter={{ ...filter, ...allFilters }}
            projectConfig={projectConfig}
            showCheckbox={true}
            refreshStatus={refreshTable}
            setRefreshStatus={setRefreshTable}
            entityType={entityType}
            entityId={entityId}
            users={users}
            tableHeight={tableHeight}
          />
        </>
      )
    }
  ];

  const releaseOptions = [
    {
      label: getRequirementName(projectConfig),
      disabled: false,
      bottomLeft: () => (
        <>
          <div className="flex flex-grow justify-start gap-4">
            {commonFilterBoxs(ENTITY_TYPES.REQUIREMENT, { releaseId: entityId, execution: false })}
          </div>
        </>
      ),
      topRight: () => (
        <>
          <div className="flex justify-end gap-4">
            {hasPrivilege(projectId, PRIVILEGES.ASSIGN_RELEASE_REQUIREMENT) && assignReqButton}
          </div>
        </>
      ),
      render: () => (
        <RequirementReactTable
          filter={{
            releaseId: entityId,
            execution: false,
            projectId,
            ...allFilters
          }}
          entityId={entityId}
          entityType={entityType}
          refreshStatus={refreshTable}
          setRefreshStatus={setRefreshTable}
          jiraConfig={jiraConfig}
          users={users}
          tableHeight={tableHeight}
          showCheckbox={true}
        />
      )
    },
    {
      label: t('Status History'),
      disabled: false,
      render: () => (
        <StatusHistory
          users={users}
          entityId={entityId}
          entityType={entityType}
          refreshStatus={refreshStatus}
          setRefreshStatus={setRefreshStatus}
        />
      )
    }
  ];

  const folderOptions = [
    {
      label: getArtifactName(projectConfig, ENTITY_TYPES.REQUIREMENT),
      bottomLeft: () => (
        <>
          <div className="flex justify-start gap-4">{commonFilterBoxs(ENTITY_TYPES.REQUIREMENT)}</div>
        </>
      ),
      render: () => (
        <div>
          <RequirementReactTable
            showContribution={true}
            filter={{ ...filter, ...allFilters }}
            jiraConfig={jiraConfig}
            entityType={entityType}
            refreshStatus={refreshTable}
            setRefreshStatus={setRefreshTable}
            users={users}
            tableHeight={tableHeight - 20}
            showCheckbox={true}
          />
        </div>
      )
    }
  ];

  const reqOptions = [
    {
      label: getArtifactName(projectConfig, ENTITY_TYPES.TEST_CASE),
      topRight: () => (
        <>
          {hasPrivilege(projectId, PRIVILEGES.ASSIGN_REQUIREMENT_TESTCASE) && (
            <div className="mb-2 flex gap-2">
              <Button
                id="commonEntityAdvancedTab-addTestCase-button"
                secondary
                text={t('assign_entity', { entity: getTestCaseName(projectConfig) })}
                size={BOTTOM_RIGHT_BUTTON_SIZE}
                icon="Plus"
                disabled={dataForm?.lockStatus?.assignTestCase}
                onClick={() => setTestCaseModal(true)}
              />
            </div>
          )}
        </>
      ),
      bottomLeft: () => (
        <div className="mt-2 flex w-full flex-grow justify-start gap-4">{commonFilterBoxs(ENTITY_TYPES.TEST_CASE)}</div>
      ),
      render: () => (
        <>
          <TestCaseReactTable
            filter={{ ...filter, ...allFilters }}
            projectConfig={projectConfig}
            refreshStatus={refreshTable}
            setRefreshStatus={setRefreshTable}
            entityType={entityType}
            entityId={entityId}
            users={users}
            tableHeight={tableHeight - 20}
            submitCallBack={submitCallBack}
            showCheckbox={true}
          />
        </>
      )
    }
  ];

  const commonOptions = [
    {
      label: t(HISTORY_MENU),
      render: () => (
        <HistoryDetails
          users={users}
          entityType={entityType}
          entityId={entityId}
          refreshStatus={refreshStatus}
          setRefreshStatus={setRefreshStatus}
        />
      )
    },
    {
      label: attachment,
      render: () => (
        <>
          <Upload
            lockStatus={dataForm?.lockStatus}
            entityType={entityType}
            entityId={entityId}
            users={users}
            submitCallBack={(value) => setAttachmentCount(value)}
          />
        </>
      )
    },
    {
      label: comment,
      render: () => (
        <>
          <Comment
            lockStatus={dataForm?.lockStatus}
            entityType={entityType}
            entityId={entityId}
            submitCallBack={(value) => setCommentCount(value)}
          />
        </>
      )
    }
  ];

  const fillAllOptions = () => {
    if (!(entityType && entityId)) return [];

    switch (entityType) {
      case ENTITY_TYPES.REQUIREMENT:
        return reqOptions.concat(commonOptions);
      case ENTITY_TYPES.FOLDER:
        return folderOptions.concat(commonOptions);
      case ENTITY_TYPES.RELEASE:
        return releaseOptions.concat(commonOptions);
      case ENTITY_TYPES.TEST_CONTAINER:
        return containerOptions.concat(commonOptions);
      case ENTITY_TYPES.TEST_CASE:
        return caseOptions.concat(commonOptions);
      case ENTITY_TYPES.TEST_FOLDER:
        return testFolderOptions.concat(commonOptions);
      case ENTITY_TYPES.TEST_CYCLE:
        return cycleOptions.concat(commonOptions);
      case ENTITY_TYPES.TEST_SUITE:
        return suiteOptions.concat(commonOptions);
      case ENTITY_TYPES.TEST_RUN:
        return runOptions;
      case ENTITY_TYPES.DEFECT:
        return defectOptions.concat(commonOptions);
      default:
        return [];
    }
  };

  const optionsArray = fillAllOptions();

  useEffect(() => {
    if ((isValidValue(prevRunId) && runId === null) || (isValidValue(prevDefectId) && defectId === null)) {
      if (submitCallBack) submitCallBack();
    }
  }, [runId, defectId]);

  useEffect(() => {
    getCounts.execute(entityId);
  }, []);

  // TAB BAR

  const returnTab = (item, index) => {
    const returnLabel = (item, index) => {
      return (
        <>
          <Tab
            as={Fragment}
            key={index}
          >
            {({ selected }) => (
              <button
                style={{ marginBottom: -2 }}
                className={classNames(
                  'px-4',
                  selected
                    ? 'border-b-2 border-primary-700 pb-3 text-sm font-normal text-primary-700'
                    : 'border-none text-sm font-normal'
                )}
              >
                {item.label}
              </button>
            )}
          </Tab>
        </>
      );
    };

    switch (item.label) {
      case 'Test Run':
        if (isSystemAdmin() || hasPrivilege(projectId, PRIVILEGES.VIEW_TEST_RUN_MANAGEMENT)) {
          return returnLabel(item, index);
        } else return null;
      case 'Test Suite':
        if (isSystemAdmin() || hasPrivilege(projectId, PRIVILEGES.VIEW_TEST_SUITE)) {
          return returnLabel(item, index);
        } else return null;
      case 'Defect':
        if (isSystemAdmin() || hasPrivilege(projectId, PRIVILEGES.VIEW_DEFECT)) {
          return returnLabel(item, index);
        } else return null;
      case 'Test Cycle':
        if (isSystemAdmin() || hasPrivilege(projectId, PRIVILEGES.VIEW_TEST_CYCLE)) {
          return returnLabel(item, index);
        } else return null;
      case 'Test Case':
        if (isSystemAdmin() || hasPrivilege(projectId, PRIVILEGES.VIEW_REQUIREMENT_TESTCASE)) {
          return returnLabel(item, index);
        } else return null;
      default:
        return returnLabel(item, index);
    }
  };

  const [selectedIndex, setSelectedIndex] = useState(0);

  return (
    <>
      <>
        <Tab.Group
          selectedIndex={selectedIndex}
          onChange={(index) => setSelectedIndex(index)}
        >
          <div
            id="advanced-tab-bar_main-container"
            className=" mb-4 "
          >
            <div
              id="advanced-tab-bar_top"
              className="flex items-center justify-between border-b-2 border-gray-200 pr-4"
            >
              <div
                id="advanced-tab-bar_top-left"
                className="flex self-end"
              >
                <Tab.List>{optionsArray?.map((item, index) => returnTab(item, index))}</Tab.List>
              </div>
              <div
                id="advanced-tab-bar_top-right"
                className="-mr-4 mb-2 flex items-center"
              >
                {optionsArray[selectedIndex]?.topRight ? optionsArray[selectedIndex].topRight() : null}
                {/* //TODO: kod base ilerde link eklenmesi durumunda üzerinde çalışılacak}
                {/* {optionsArray?[selectedIndex]?.subTabs && optionsArray[selectedIndex]?.subTabs.length > 0 && (
                <>
                  {optionsArray[selectedIndex]?subTabs.map((item, index) => {
                    let active = isActive(index);

                    return (
                      <div key={index} className="flex flex-grow">
                        <div
                          className={`flex items-center ${item?.icon && 'gap-1'} ${true && 'text-primary-700'}`}
                          onClick={() => setSelectedIndex(index)}>
                          {item?.icon && (active ? item.icon('text-primary-600') : item.icon('text-gray-700'))}
                          {
                            <Heading
                              type="small"
                              color={active ? 'text-primary-600' : 'text-gray-700'}
                              text={item?.label}
                            />
                          }
                        </div>
                      </div>
                    );
                  })}
                </>
              )} */}
              </div>
            </div>
            <div id="advanced-tab-bottom">
              <div
                id="advanced-tab-bar_bottom-left"
                className="flex flex-grow gap-4 "
              >
                {optionsArray?.[selectedIndex]?.bottomLeft ? optionsArray[selectedIndex].bottomLeft() : null}
              </div>
              <Tab.Panels>
                {optionsArray?.map((item, index) => (
                  <Tab.Panel key={index}>{item.render()}</Tab.Panel>
                ))}
              </Tab.Panels>
            </div>
          </div>
        </Tab.Group>
      </>
      {testCaseModal && (
        <RequirementAddTestCasesModal
          isOpen={testCaseModal}
          closeModal={() => setTestCaseModal(false)}
          submitCallBack={() => {
            setRefreshTable(true);
            (projectConfig?.configuration?.requirement?.assignedTestCaseControl == 'true' ||
              projectConfig?.configuration?.requirement?.testAutomationControl == 'true') &&
              submitCallBack();
          }}
          entityType={entityType}
          entityId={entityId}
        />
      )}
      {isAddRequirementModalOpen && (
        <AddRequirementModal
          isOpen={isAddRequirementModalOpen}
          closeModal={() => setIsAddRequirementModalOpen(false)}
          submitCallBack={() => setRefreshTable(true)}
          entityType={entityType}
          entityId={entityId}
        />
      )}
      {addTestcaseModalVis === true && (
        <TestSuiteCreateRunModal
          testSuiteId={entityId}
          isOpen={addTestcaseModalVis}
          submitCallBack={() => setRefreshTable(true)}
          closeModal={() => setAddTestcaseModalVis(false)}
          //TODO: WIP Parameters feature
          // setConfigureParametersModalOpen={() => setConfigureParametersModalOpen(true)}
        />
      )}
      {/* 
      //TODO: WIP Parameters feature 
      */}
      {/* {configureParametersModalOpen && (
        <ConfigureParametersModal
          isOpen={configureParametersModalOpen}
          closeModal={() => setConfigureParametersModalOpen(false)}
          // submitCallBack={() => setRefreshTable(true)}
          testSuiteId={entityId}
        />
      )} */}
      {defectModal && (
        <DefectModal
          {...((entityType === ENTITY_TYPES.TEST_RUN || entityType === ENTITY_TYPES.TEST_SUITE) &&
            dataForm?.testCycle?.id && { testCycleId: dataForm?.testCycle?.id })}
          {...(entityType === ENTITY_TYPES.TEST_RUN && dataForm?.id && { runId: dataForm?.id })}
          {...(entityType === ENTITY_TYPES.TEST_CYCLE && dataForm?.id && { testCycleId: dataForm?.id })}
          isOpen={defectModal}
          closeModal={() => setDefectModal(false)}
          submitCallBack={() => setRefreshTable(true)}
          projectConfig={projectConfig}
        />
      )}
    </>
  );
};

export default CommonEntityAdvancedTab;
