import {
  getContainerName,
  getCycleName,
  getDefectName,
  getFolderName,
  getReleaseName,
  getRequirementName,
  getRunName,
  getSuiteName,
  getTestCaseName,
  getTestFolderName
} from '../../../helpers/ArtifactNameHelper';
import { ENTITY_TYPES } from '../../../constants';
import { getOrderedByName } from '../../../helpers/Helper';
import { isHasUserViewRoles, isProjectManager, isSystemAdmin } from '../../../helpers/PriviligeHelper';

export const menuItems = (projectConfigs, projectId, project) => {
  if (isProjectManager(projectId)) {
    let csMenus = getOrderedByName([
      {
        name: getReleaseName(projectConfigs),
        path: ENTITY_TYPES.RELEASE,
        type: ENTITY_TYPES.RELEASE
      },
      {
        name: getDefectName(projectConfigs),
        path: ENTITY_TYPES.DEFECT,
        type: ENTITY_TYPES.DEFECT
      },
      {
        name: getContainerName(projectConfigs),
        path: ENTITY_TYPES.TEST_CONTAINER,
        type: ENTITY_TYPES.TEST_CONTAINER
      },
      {
        name: getCycleName(projectConfigs),
        path: ENTITY_TYPES.TEST_CYCLE,
        type: ENTITY_TYPES.TEST_CYCLE
      },
      {
        name: getSuiteName(projectConfigs),
        path: ENTITY_TYPES.TEST_SUITE,
        type: ENTITY_TYPES.TEST_SUITE
      },
      {
        name: getTestFolderName(projectConfigs),
        path: ENTITY_TYPES.TEST_FOLDER,
        type: ENTITY_TYPES.TEST_FOLDER
      },
      {
        name: getRunName(projectConfigs),
        path: ENTITY_TYPES.TEST_RUN,
        type: ENTITY_TYPES.TEST_RUN
      },
      {
        name: getTestCaseName(projectConfigs),
        path: ENTITY_TYPES.TEST_CASE,
        type: ENTITY_TYPES.TEST_CASE
      },
      {
        name: getFolderName(projectConfigs),
        path: ENTITY_TYPES.FOLDER,
        type: ENTITY_TYPES.FOLDER
      }
    ]);

    let sfMenus = [
      {
        name: getReleaseName(projectConfigs),
        path: ENTITY_TYPES.RELEASE,
        type: ENTITY_TYPES.RELEASE
      },
      {
        name: getRequirementName(projectConfigs),
        path: ENTITY_TYPES.REQUIREMENT,
        type: ENTITY_TYPES.REQUIREMENT
      },
      {
        name: getDefectName(projectConfigs),
        path: ENTITY_TYPES.DEFECT,
        type: ENTITY_TYPES.DEFECT
      },
      {
        name: getCycleName(projectConfigs),
        path: ENTITY_TYPES.TEST_CYCLE,
        type: ENTITY_TYPES.TEST_CYCLE
      },
      {
        name: getTestCaseName(projectConfigs),
        path: ENTITY_TYPES.TEST_CASE,
        type: ENTITY_TYPES.TEST_CASE
      },
      { name: 'Project', path: 'project', type: 'project' }
    ];

    let flowMenus = [
      {
        name: getDefectName(projectConfigs),
        path: ENTITY_TYPES.DEFECT,
        type: ENTITY_TYPES.DEFECT
      },
      {
        name: getCycleName(projectConfigs),
        path: ENTITY_TYPES.TEST_CYCLE,
        type: ENTITY_TYPES.TEST_CYCLE
      }
    ];

    let configMenus = getOrderedByName([
      {
        name: getDefectName(projectConfigs),
        path: ENTITY_TYPES.DEFECT,
        type: ENTITY_TYPES.DEFECT
      },
      {
        name: getRequirementName(projectConfigs),
        path: ENTITY_TYPES.REQUIREMENT,
        type: ENTITY_TYPES.REQUIREMENT
      },
      {
        name: getRunName(projectConfigs),
        path: ENTITY_TYPES.TEST_RUN,
        type: ENTITY_TYPES.TEST_RUN
      }
    ]);

    return [
      {
        name: 'Configuration',
        path: 'configuration',
        subNav: [
          { name: 'Artifact Names', path: 'artifact-names' },
          { name: 'Menu Names', path: 'menu-names' },
          ...configMenus
        ]
      },
      {
        name: 'Dashboard',
        path: 'dashboard',
        subNav: [
          { name: 'Dashboards', path: 'dashboards', type: 'dashboard' },
          { name: 'Charts', path: 'charts', type: 'chart' }
        ]
      },
      {
        name: 'Fields',
        path: 'fields',
        subNav: [
          {
            name: 'Custom Field',
            path: 'custom-fields',
            subNav: [
              {
                name: getRequirementName(projectConfigs),
                path: ENTITY_TYPES.REQUIREMENT,
                type: ENTITY_TYPES.REQUIREMENT
              },
              ...csMenus
            ]
          },
          {
            name: 'System Field',
            path: 'system-fields',
            subNav: getOrderedByName(sfMenus)
          }
        ]
      },
      {
        name: 'Integrations',
        path: 'integration',
        subNav: [
          // { name: "Api" , path:"api"},
          // { name: "Hook", path:"hook" },
          { name: 'Mail', path: 'mail', type: 'mail' },
          { name: 'Jira', path: 'jira', type: 'jira' },
          { name: 'Automation', path: 'automation', type: 'automation' },
          /* TODO: Entegrasyon komple tamamlanınca açılacak)*/
          // { name: 'Visium Go', path: 'visiumGo', type: 'visiumGo' }
        ]
      },
      {
        name: 'User & Role Management',
        path: 'user',
        subNav: [
          { name: 'Users', path: 'users' },
          { name: 'Roles', path: 'roles' },
          { name: 'User Groups', path: 'user-groups' }
        ]
      },
      { name: 'Workflows', path: 'workflow', subNav: getOrderedByName(flowMenus) },

      ...(project?.isTemplate && isSystemAdmin() ? [{ path: 'related-projects', name: 'Related Projects' }] : []),
      {
        name: 'Versions',
        path: 'versions'
      }
    ];
  } else if (isHasUserViewRoles(projectId)) {
    return [
      {
        name: 'Dashboard',
        path: 'dashboard',
        subNav: [
          { name: 'Dashboards', path: 'dashboards', type: 'dashboard' },
          { name: 'Charts', path: 'charts', type: 'chart' }
        ]
      },
      {
        name: 'User & Role Management',
        path: 'user',
        subNav: [
          { name: 'Users', path: 'users' },
          { name: 'Roles', path: 'roles' },
          { name: 'User Groups', path: 'user-groups' }
        ]
      },
      {
        name: 'Versions',
        path: 'versions'
      }
    ];
  } else {
    return [
      {
        name: 'Dashboard',
        path: 'dashboard',
        subNav: [
          { name: 'Dashboards', path: 'dashboards', type: 'dashboard' },
          { name: 'Charts', path: 'charts', type: 'chart' }
        ]
      },
      {
        name: 'Versions',
        path: 'versions'
      }
    ];
  }
};
