import React, { useEffect, useState } from 'react';
// DESIGN SYSTEM
import { Body, Button } from '../../designSystem/DesignSystem';
// HELPER & CONSTANTS
import { ENTITY_TYPES } from '../../constants';
import { getArtifactName, getDefectName, getExecutionName } from '../../helpers/ArtifactNameHelper';
import { INFO_MESSAGES, showErrorMes, showSuccessMes } from '../../helpers/NotificationHelper';
// HOOKS & API
import { useApi } from '../../hooks/UseApi';
import UseProjectConfig from '../../hooks/UseProjectConfig';
import TestRunExecutionApi from '../../api/TestRunExecutionApi';
// COPONENTS
import DefectReactTable from '../defect/DefectReactTable';
import DefectModal from '../defect/Modal/DefectModal';
import TestRunExecutionModal from './modal/TestRunExecutionModal';
import TestRunExecutionTab from './tabs/TestRunExecutionTab';
import Upload from '../../components/upload/Upload';
import ConfirmationModal from '../../components/confirm/ConfirmationModal';
import EntityDetails from '../../components/entityDetails/EntityDetails';
import SelectedTableFilters from '../../components/filtering/SelectedTableFilters';
import AdvancedTabBar from '../../components/tab/AdvancedTabBar';
import { useTranslation } from 'react-i18next';
import Tippy from '@tippyjs/react';
import TestRunExecutionAutomationStepsTab from './tabs/TestRunExecutionAutomationSteps';
import { isCertainTrue } from '../../helpers/Helper';

const TestRunExecutionDetails = ({ runExecution, setSelectedExecution, projectId, users, callback = () => {} }) => {
  const projectConfig = UseProjectConfig();

  const [allFilters, setAllFilters] = useState({});
  const [refreshTable, setRefreshTable] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [defectModal, setDefectModal] = useState(false);
  const [executionModal, setExecutionModal] = useState(false);
  const { t } = useTranslation();

  const testRunExecutionDelete = useApi({
    name: 'delete test run execution',
    api: TestRunExecutionApi.delete,
    initialValue: false,
    autoLoad: false,
    onSuccess: () => {
      showSuccessMes(INFO_MESSAGES.delete);
      setOpenConfirmModal(false);
      callback();
      setSelectedExecution();
    },
    onError: (err) => {
      showErrorMes(err);
      setOpenConfirmModal(false);
    }
  });

  useEffect(() => {
    setRefreshTable(true);
  }, [runExecution]);

  return (
    <div className="flex-grow pl-4">
      <EntityDetails
        type={ENTITY_TYPES.TEST_EXECUTION}
        loading={false}
        data={runExecution}
      />
      {runExecution?.completed ? (
        <AdvancedTabBar
          defaultIndex={0}
          tabs={[
            {
              label: t('Steps'),
              disabled: false,
              render: () => (
                <TestRunExecutionTab
                  execution={runExecution}
                  projectId={projectId}
                  preconditions={runExecution?.testCase?.preconditions}
                />
              )
            },
            ...(
              isCertainTrue(projectConfig?.configuration?.automation?.showTestRunAutomationSteps)
                ? [{
                  label: t('Automation Steps'),
                  disabled: false,
                  render: () => (
                    <>
                      <TestRunExecutionAutomationStepsTab execution={runExecution} />
                    </>
                  )
                }] : []
            ),
            {
              label: getArtifactName(projectConfig, ENTITY_TYPES.DEFECT),
              disabled: false,
              topRight: () => (
                <div className="mt-2 flex justify-end gap-2">
                  <Button
                    id="testRunExecutionDetails-add-button"
                    secondary
                    text={getDefectName(projectConfig)}
                    type="button"
                    icon="Plus"
                    size={'xsmall'}
                    onClick={() => setDefectModal(true)}
                  />
                </div>
              ),
              bottomLeft: () => (
                <div className="flex flex-grow justify-start gap-4">
                  <div className="flex flex-grow gap-2">
                    <SelectedTableFilters
                      allFilters={allFilters}
                      setAllFilters={setAllFilters}
                      entityType={ENTITY_TYPES.DEFECT}
                      setRefreshTable={setRefreshTable}
                    />
                  </div>
                </div>
              ),
              render: () => (
                <DefectReactTable
                  refreshStatus={refreshTable}
                  users={users}
                  setRefreshStatus={setRefreshTable}
                  filter={{ ...allFilters, projectId, testExecutionId: runExecution?.id }}
                />
              )
            },
            {
              label: t('Attachments'),
              disabled: false,
              render: () => (
                <div className="mt-4 flex flex-grow gap-8">
                  <div className="flex-grow">
                    <Body
                      type="medium"
                      bold
                      text={`${getExecutionName(projectConfig)}` + ' ' + t('Attachments')}
                    />
                    <Upload
                      entityType={ENTITY_TYPES.TEST_EXECUTION}
                      entityId={runExecution?.id}
                      users={users}
                    />
                  </div>
                  <Tippy
                    theme="custom-theme"
                    content={t('You can use the Attachments field in the Steps tab to add Step Attachment.')}
                    placement="top"
                    delay={[500, 250]}
                  >
                    <div className="flex-grow">
                      <Body
                        type="medium"
                        bold
                        text={t(`Step Attachments`)}
                      />
                      <Upload
                        entityType={ENTITY_TYPES.TEST_EXECUTION}
                        entityId={runExecution?.id}
                        users={users}
                        stepAttachment={true}
                      />
                    </div>
                  </Tippy>
                </div>
              )
            }
          ]}
        />
      ) : (
        <>
          <Body
            text={t(`This test execution is not completed yet.`)}
            type="large"
          />
          <Button
            id="testRunExecutionDetails-continue-button"
            primary
            text={t('Continue to Update')}
            onClick={() => setExecutionModal(true)}
          />
          <Button
            id="testRunExecutionDetails-delete-button"
            secondary
            icon="Trash"
            onClick={() => setOpenConfirmModal(true)}
          />
          {executionModal && (
            <TestRunExecutionModal
              isOpen={executionModal}
              close={(value) => {
                setExecutionModal(false);
                callback();
                setSelectedExecution(value);
              }}
              executionId={runExecution?.id}
              runId={runExecution?.testRun?.id}
              users={users}
              projectId={projectId}
              preconditions={runExecution?.testCase?.preconditions}
            />
          )}
        </>
      )}

      {defectModal && (
        <DefectModal
          testCycleId={runExecution?.testCycle?.id}
          runId={runExecution?.testRun?.id}
          selectedExecution={runExecution}
          isOpen={defectModal}
          closeModal={() => setDefectModal(false)}
          submitCallBack={() => setRefreshTable(true)}
          projectConfig={projectConfig}
        />
      )}

      {openConfirmModal && (
        <ConfirmationModal
          isOpen={openConfirmModal}
          closeModal={() => setOpenConfirmModal(false)}
          handleConfirm={() => runExecution?.id && testRunExecutionDelete.execute(runExecution?.id)}
          loading={testRunExecutionDelete?.loading}
          header={t('Confirm Delete')}
          content={t(`Do you really want to delete this execution?`)}
        />
      )}
    </div>
  );
};

export default TestRunExecutionDetails;
