import { axios } from './ApiClient';
import { BASE_URL } from './CommonApiHelper';

export const TEST_RUN_EXECUTION_AUTOMATION_STEPS_PATH = BASE_URL + 'test-run-execution-automation-steps';

class TestRunExecutionAutomationStepApi {
  async getSteps(data) {
    const resp = await axios.get(`${TEST_RUN_EXECUTION_AUTOMATION_STEPS_PATH}`, {
      params: { testRunExecutionId: data.testRunExecutionId }
    });
    return resp.data;
  }
}

export default new TestRunExecutionAutomationStepApi();
